:root {
    --ratio: .75;
 
  }

.profile {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px;
    position: fixed;
    top: 50px;
    /* left: 0px; */
    width: 100vw;
    height: 100vh;
    min-height: 99vh;
    background-color: var(--bg);
    justify-items: center;
    align-items: top;
    transition: all 1s ease;
    overflow: auto;
}

.profile_menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    position: relative;
    top: 0;
    left: 0;
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 20vh 0 0 0;
    background-color: var(--primary);
    z-index: 1;
}

.profile_hud {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    position: relative;
    top: 0px;
    left: 0;
    width: 100%;
    max-width: max-content;
    height: max-content;
    max-height: 100vh;
    margin: 0px 50px;
    justify-items: center;
    align-items: flex-start;
    /* overflow-y: auto; */
}

.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: relative;
    width: 90%;
    height: max-content;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 10px;
    justify-items: left;
    align-items: center;
    overflow: hidden;
}

.info span {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: var(--gridGap);
    align-items: center;
}

.live_view {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    width: calc(100vw * var(--ratio));
    height: calc(50vw * var(--ratio));
    margin: 0;
    /* background-color: var(--primary); */
    border-radius: 20px;    
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

.live_indicator {
    width: 15px;
    height: 15px;
    border: solid 2px var(--primary);
    border-radius: 100px;
    margin: 20px 10px;
}

@media screen and (max-width: 900px) {
  
}

