.home_layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    gap: 30px;
    position: relative;
    width: 100%;
    height: max-content;
    min-height: 100vh;
    justify-items: center;
    align-items: top;
}

