.avatar {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: var(--gridGap);
    position: relative;
    width: 200px;
    height: 200px;
    margin: 7px 7px 25px 7px;
    background-color: var(--primary);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    outline: solid 2px var(--primary);
    outline-offset: 5px;        
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.avatar > input {
    opacity: 0;
}
