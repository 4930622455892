.navbar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 50px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  /* backdrop-filter: blur(5px); */
  overflow: hidden;
  z-index: 1000;
}

.main_menu {
  display: grid;
  grid-auto-columns: repeat(6, 1fr);
  grid-template-rows: max-content;
  grid-auto-flow: column;
  gap: var(--gridGap);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  background-color: var(--primary);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  backdrop-filter: blur(5px);
  transition: all 1s ease;
  justify-items: center;
  align-items: center;
  z-index: 1;
}

.menu_btn {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0px;
  transition: all 1s ease;
  cursor: pointer;
}

.menu_btn h1 {
  color: var(--bg);
}

.menu_btn:hover {
  background-color: rgba(255, 255, 255, .25);
  border-radius: 10px;
  transition: all 1s ease;
  cursor: pointer;
}

.log_controls {
  display: grid;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  position: relative;
  width: 100%;
  height: max-content;
  z-index: 1;
}

.bg {
  fill: var(--bg);
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  justify-items: center;
  align-items: center;
  opacity: 1;
}

.logomark {
  fill: var(--primary);
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  justify-items: center;
  align-items: center;
  opacity: 1;
  z-index: 1000;
}

.wordmark {
  fill: var(--primary);
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  justify-items: center;
  align-items: center;
  opacity: 1;
}

@media screen and (max-width: 900px) {

  .main_menu {
    display: grid;
    grid-auto-columns: none;
    grid-template-columns: 1fr;
    grid-template-rows: none;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    gap: var(--gridGap);
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 10vh 0;
    background-color: var(--primary);
    backdrop-filter: blur(5px);
    justify-items: left;
    align-items: center;
    transition: all 1s ease;
    overflow: hidden;
    z-index: 1;
  }

}
