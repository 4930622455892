.blog_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    gap: 30px;
    position: relative;
    width: 100vw;
    max-width: 600px;
    height: max-content;
    min-height: 75vh;
    margin: 0;
    padding: 0;
    justify-items: center;
    align-items: flex-start;
}

.blogs_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 10vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.blog_sidebar {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: fixed;
    top: 0;
    left: 0;
    width: max-content;
    height: max-content;
    min-height: 100vh;
    margin: 0;
    padding: 125px 0 0 0;
    background-color: var(--primary);
    z-index: 10;
}

.blog_btn {
    position: absolute;
    right: 8vw;
}

.blogcard {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: relative;
    width: calc(100% - 60px);
    height: max-content;
    padding: 30px;
    background-color: var(--shade);
    border-radius: 25px;
}

.blogcard img, .blogcard video {
    width: 100%;
    height: auto;
}

.blog_forms {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    position: fixed;
    bottom: 0;
    width: 90%;
    max-width: 60vw;
    height: max-content;
    margin: 0;
    padding: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(10px);
    justify-items: center;
    align-items: center;
    transition: all 1s ease;
    z-index: 10;
}

.blog_forms input, .blog_forms textarea {
    width: 100%;
    max-width: none;  
}

.blog_options_menu {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: var(--gridGap);
}

.options_button {
    display: grid;
    grid-auto-columns: 1fr;
    width: max-content;
    height: max-content;
    overflow: hidden;
    cursor: pointer;
}

.options_button input {
    position: absolute;
    opacity: 0;
    z-index: 10;
}

.delete_button {
    display: grid;
    grid-auto-columns: 1fr;
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 3px;
    background-color: var(--tint);
    overflow: hidden;
    cursor: pointer;
    justify-self: right;
}