.subscription_statusbar {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    position: relative;
    /* top: 50px;
    right: 0; */
    width: max-content;
    max-width: 100%;
    height: max-content;
    margin: 10px 0px;
    padding: 5px;
    background-color: var(--tint);
    border: solid 2px var(--primary);
    border-radius: 10px;
    justify-items: left;
}

.subscription_statusbar h1 {
    font-size: 12px;
}