
.log_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: absolute;
    top: 0px;
    width: 40%;
    min-width: 200px;
    /* max-width: 700px; */
    height: max-content;
    margin: 0px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 530px -10px rgba(0, 0, 0, .3);
    justify-items: center;
    align-items: center;
    backdrop-filter: blur(15px);
    transition: all 1s ease;
    z-index: 100;
  }

.login {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
    gap: var(--gridGap);
    position: relative;
    width: calc(100% - 20px);
    max-width: 100%;
    height: max-content;
    margin: 0px;
    padding: 10px 50px;
    border-radius: 10px;
    justify-items: center;
    align-items: center;
}

@media screen and (max-width: 900px) {

  .log_container {
    grid-auto-rows: max-content;
    top: auto;
    /* right: auto; */
    width: 90%;
    min-width: 300px;
    /* max-width: 700px; */
    height: max-content;
    margin: 0px;
    padding: 0px 0 10px 0;
    background-color: var(--shade);
    border-radius: 20px;
    /* border-radius: 20px; */
    box-shadow: 0 0 530px -10px rgba(0, 0, 0, .3);
    justify-items: center;
    align-items: center;
    align-self: center;
    backdrop-filter: blur(15px);
    transition: all 1s ease;
    z-index: 100;
  }
  
  .login {
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
}

.hamburger {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  top: 0;
  right: 10px;
  justify-items: center;
  align-items: center;
  z-index: 1000;
}

}

