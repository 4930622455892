.uploader {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: max-content;
    height: max-content;
    /* min-height: 300px; */
    padding: 10px;
    background-color: transparent;
    border-radius: 10px;
    justify-items: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    transition: all 1s ease;
    z-index: 1000;
}

.loading_component {
    position: fixed;
    width: 100%;
    max-width: 300px;
    height: max-content;
    background-color: var(--primary);
    color: greenyellow;
    justify-self: center;
    align-self: center;
}


/* .uploader {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: relative;
    top: 25vh;
    left: 0;
    width: 100%;
    max-width: 100vw;
    min-height: 100%;
    padding: 10px;
    background-color: var(--bg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;    
    justify-items: center;
    align-items: center;
    z-index: 100;
}

.uploader .circle_wrapper input, .uploader .circle_wrapper svg {
    position: absolute;
    width: 100%;
} */
