.splash {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg);
  background-size: cover;
  background-repeat: no-repeat;
  justify-items: center;
  align-items: center;
  overflow: auto;
}

.section {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  width: 100%;
  max-width: 600px;
  height: max-content;
  min-height: 400px;
  margin: 25px;
  justify-items: center;
  align-items: center;
}

.mastheader {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content;
  gap: 40px;
  position: relative;
  top: -30vh;
  width: 600px;
  height: max-content;
  background-color: var(--bg);
  border-radius: 30px;
  justify-items: center;
  align-items: center;
}

.mastheader h1 {
  font-size: 16px;
}

.button_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  gap: 30px;
  position: relative;
  width: 100%;
  height: max-content;
}

.button {
  display: grid;
  grid-template-columns: max-content max-content;
  width: 100%;
  justify-items: center;
  justify-items: center;
  cursor: pointer;
}

