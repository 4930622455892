.calendar_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content 1fr;
  gap: var(--gridGap);
  position: relative;
  width: 100%;
  min-width: min-content;
  padding: 10px;
  border: solid 1px var(--light);
  border-radius: 10px;
  justify-items: center;
  align-items: top;
  z-index: 1;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  gap: var(--gridGap);
  position: relative;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  z-index: 1;
}

.calendar_header {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: var(--gridGap);
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 10px;
  justify-items: center;
  align-items: center;
  overflow: hidden;
}

.calendar_header .col {
  grid-template-columns: repeat(7, 1fr);
}

.calendar_header h3 {
  font-size: 1vw;
  font-weight: 400;
}


.cal_nav {
  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
  gap: var(--gridGap);
  width: 100%;
  justify-items: left;
  align-items: center;

}

.cal_nav  h1, .cal_nav h2 {
  font-size: 3vw;
}

.cal_nav  h1 {
  justify-self: right;
}

.calendarcard {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: repeat(3, max-content);
  gap: var(--gridGap);
  width: 5vw;
  height: 5vw;
  border: solid 1px var(--light);
  border-radius: 10px;
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0);
  justify-items: left;
  align-items: top;
  cursor: pointer;
  overflow: hidden;
}

.calendarcard:hover {
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, .07);
}

.calendarcard h1 {
  font-size: 1vw;
  margin: 5px;
}


.appointmentcard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  gap: 10px;
  position: relative;
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  height: max-content;
  padding: 20px;
  background-color: var(--bg);
  border: solid 1px var(--light);
  border-radius: 10px;
  box-shadow: 0 0 100px -1px rgba(0, 0, 0, .3);
  justify-items: center;
  align-items: top;
  z-index: 2;
}

.appointmentcard > form {
  justify-items: left;
  cursor: pointer;
}

.appointmentcard > form > input {
  width: 1fr;
  height: 30px !important;
  margin: 0;
  padding: 0 10px 0 10px;
  border-radius: 100px;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.appointmentcard > form > .col {
  grid-template-columns: 1fr 1fr;
  margin: 0 0 0px 0;
}

.appointmentcard button {
  width: 100%;
}

.appointmentcard > h1 {
  align-self: flex-start;
  font-size: 36px;
}

.appointmentcard > h2 {
  font-size: 14px;
}

.appointmentcard > h3 {
  width: max-content;
  height: max-content;
  padding: 5px 20px 5px 20px;
  background-color: var(--tint);
  border: solid 1px var(--light);
  border-radius: 100px;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.appointmentcard > h6 {
  font-size: 36px;
  margin: 0 0 20px 0;
}



.datepicker_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content 1fr;
  gap: var(--gridGap);
  position: absolute;
  top: 200px;
  left: 100px;
  width: 100%;
  min-width: 200px;
  max-width: 280px;
  min-height: max-content;
  padding: 10px;
  background-color: var(--bg);
  border: solid 1px var(--light);
  border-radius: 10px;
  box-shadow: 0 0 100px -1px rgba(0, 0, 0, .3);
  justify-items: center;
  align-items: top;
  overflow: hidden;
  z-index: 100;
}


.datepicker {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  gap: var(--gridGap);
  position: relative;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
}

.datepicker_header {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: var(--gridGap);
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 10px;
  justify-items: center;
  align-items: center;
  overflow: hidden;
}

.datepicker_header .col {
  grid-template-columns: repeat(7, 1fr);
}

.datepicker_header h3 {
  font-size: 6px;
  font-weight: 400;
}


.datepicker_nav {
  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
  gap: var(--gridGap);
  width: 100%;
  justify-items: left;
  align-items: center;

}

.datepicker_nav  h1, .datepicker_nav h2 {
  font-size: 2vh;
}

.datepicker_nav  h1 {
  justify-self: right;
}


.datepickercard {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: repeat(3, max-content);
  gap: var(--gridGap);
  width: 100%;
  height: 100%;
  border: solid 1px var(--light);
  border-radius: 100px;
  background-color: none;
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0);
  justify-items: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.datepickercard:hover {
  box-shadow: 0 0 20px -1px rgba(0, 0, 0, .07);
}

.datepickercard h1 {
  font-size: 1vw;
  margin: 5px;
}