.oops_layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    position: relative;
    top: 25vh;
    width: 100vw;
    height: max-content;
    min-height: 100vh;
    justify-items: center;
    align-items: center;
}

.oops_layout h1 {
    font-size: 76px;
}

.oops_layout p {
    justify-self: center;
    text-align: center;
}