.admin_layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 30px;
    position: relative;
    width: 100vw;
    height: max-content;
    margin: 0;
    padding: 0;
    justify-items: center;
    align-items: top;
    z-index: 1;
}

.admin_layout .content_panel, .admin_layout .right_side_panel {
    top: 0px;
    z-index: 1;
}

.hero_component {
    display: grid;
    width: 85%;
    height: max-content;
    margin: 50px 0 0 0;
    padding: 20px;
    /* background-color: var(--secondary); */
    border: solid 1px var(--tint);
    border-radius: 20px;
    justify-items: center;
    align-items: center;
    overflow: hidden;
}
