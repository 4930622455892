.plans_wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px;
    width: 100%;
    height: 100vh;
    padding: var(--globalTopMargin);
    justify-items: center;
    align-items: center;
}

.plans_card {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content max-content 1fr max-content;
    gap: 20px;
    position: relative;
    aspect-ratio: 27 / 40;
    width: 80%;
    max-width: 300px;
    margin: 20px;
    padding: 20px;
    background-color: var(--tint);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 10px;    
    justify-items: center;
    align-items: top;
}

.plan_cover {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 10px; 
}

.purchase_btn {
    width: 100%;
    max-width: 120px;
    height: max-content;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--tint);
    backdrop-filter: blur(3px);
    justify-self: right;
}

.pending {
    display: grid;
    position: absolute;
    width: 100%;
    max-width: 200px;
    height: max-content;
    margin: 0;
    padding: 10px;
    background-color: var(--shade);
    border-radius: 10px;
    justify-items: center;
    align-items: center;
    z-index: 100;
}