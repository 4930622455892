.logo {
    display: grid;
    width: auto;
    height: 100px;
    margin: 40px;
    fill: var(--secondary);
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    justify-items: left;
    align-items: center;
    opacity: 1;
  }

  .logo_container {
    display: grid;
    position: relative;
    top: 0px;
    left: 0;
    width: max-content;
    height: max-content;
    margin: 20px;
    padding: 0;
    justify-items: left;
    align-items: center;
    z-index: 10;
  }