.modules_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px;
    position: relative;
    /* top: 0px; */
    width: 100vw;
    height: max-content;
    /* background-color: var(--primary); */
    justify-items: center;
    align-items: center;
    transition: all 1s ease;
    overflow: hidden;
}

.profile_stage {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 20px;
    position: relative;
    top: 0px;
    width: 90%;
    max-width: 900px;
    height: max-content;
    background-color: transparent;
    justify-items: center;
    align-items: center;
    z-index: 2;
}

.profile_stage .complete_btn {
    margin: 50px 0;
}

.profile_stage h1, .profile_stage h2, .profile_stage h3, .profile_stage p {
    cursor: pointer;
}

.profile_stage h1 {
    font-size: 36px;
}

.profile_stage h2 {
    font-size: 24px;
}

.materials_menu {
    grid-template-columns: max-content max-content;
    grid-auto-flow: column;
    width: calc(100% - 3vw);
    height: max-content;
    margin: 0 0 20px 0;
    padding: 0 20px;
    background-color: var(--primary);
    border-radius: 20px;
    justify-items: left;
    align-items: center;
    z-index: 2;
}

.materials_menu button {
    width: max-content;
}

.material_wrapper {
    display: grid;
    gap: var(--gridGap);
    position: relative;
    width: calc(100% - (4vw -4px));
    min-width: 90%;
    height: max-content;
    margin: 0px 0;
    padding: 0vw;
    /* border: solid 2px var(--primary); */
    border-radius: 10px;
    justify-items: center;
    align-items: center;
}

.material_wrapper h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: max-content;
    min-width: 200px;
    margin: 0;
    padding: 0 0 0 10px;
    background-image: url("../../images/jpg/stroke.png");
    background-repeat: no-repeat;
    border-bottom-right-radius: 10px;
    color: var(--bg);
    font-size: 24px;
    text-align: left;
}

.media_card {
    display: grid;
    position: relative;
    width: 90%;
    gap: 30px;
    padding: 0px;
    border-bottom: dotted 2px var(--shade);
    justify-items: left;
}

.media_card video, .media_card img {
    width: 100%;
    max-width: 900px;
    height: auto;
    border-radius: 20px;
    object-fit: cover;
}

.media_card h1 {
    position: relative;
    top: 0;
    width: 100%;
    background-color: transparent;
    color: var(--primary);
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.media_card p {
    color: var(--primary);
    font-size: 18px;
}

.pdf h1 {

    font-size: 24px;
}

@media screen and (max-width: 900px) {

    .material_wrapper {
        display: grid;
        position: relative;
        width: calc(80% - (4vw -4px));
        /* min-width: 80%; */
        max-width: 95%;
        height: max-content;
        margin: 0px 0;
        padding: 10vw 0 0 0;
        /* border: solid 2px var(--primary); */
        border-radius: 10px;
        justify-items: center;
        align-items: center;
    }
  
}