.facetime {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: max-content;
  justify-items: center;
  align-items: center;
  z-index: 1005;
}

.facetime_controls {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: var(--gridGap);
  position: fixed;
  left: 50vw;
  bottom: 3vh;
  width: 300px;
  padding: 10px 0 10px 0;
  background-color: var(--tint);
  border: solid 1px var(--light);
  border-radius: 10px;
  box-shadow: 0 0 100px -1px rgba(0, 0, 0, 0.25);
  justify-items: center;
  z-index: 101;
}

.facetime svg {
  width: 50px;
  margin: 0;
  background-color: var(--tint);
  border: solid 1px var(--light);
  border-radius: 10px;
}

.start_menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: var(--gridGap);
  position: relative;
  padding: 10px;
  background-color: var(--tint);
  border: solid 1px var(--light);
  border-radius: 10px;
  justify-items: center;
  align-items: center;
}

.start_menu .col {
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.start_menu input {
    text-align: center;
}

.videos {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--dark);
  backdrop-filter: blur(10px);  
  justify-items: center;
  align-items: center;
  transition: all 1s ease;
  overflow: hidden;
  object-fit: contain;
  z-index: 1;
}

.videos video {
  position: relative;
  margin: 0 50px;
  border-radius: 10px;
  object-fit: cover;
}

.local {
  top: 150px;
  width: 200px !important;
  max-width: 200px;
  height: 200px !important;
  align-self: flex-start;
  z-index: 2;
}

.remote {
  width: 80% !important;
  height: auto !important;
  aspect-ratio: 16/9;
  background-color: grey;
}
