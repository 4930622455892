.socialmedia_toolbar {
    display: grid;
    grid-template-columns: max-content repeat(auto-fit, 30px);
    grid-template-rows: max-content;
    grid-auto-flow: column;
    gap: 1vw;
    position: relative;
    left: 5px;
    width: max-content;
    height: max-content;
    padding: 5px;
    background-color: var(--shade);
    border-radius: 10px;
    justify-self: center;
    align-items: center;
}

.socialmedia_toolbar button svg {
    width: 30px;
    height: 30px;
    margin: 0 !important;
    border-radius: 100%;
}

.socialmedia_toolbar h3 {
    color: var(--grey);
    font-size: 2vh;
}