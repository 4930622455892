.edit_box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    justify-items: center;
    align-items: center;
    z-index: 10;
}

.edit_box .row {
    justify-items: center;
}