.create_layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    width: 100vw;
    height: max-content;
    min-height: 99vh;
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

.create_layout h1 {
    color: var(--primary);
    font-size: 144px;
    font-weight: 900;
}

.create_layout h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
}

.create_copy_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 40px;
    position: relative;
    width: 100%;
    max-width: 900px;
    height: max-content;
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

.create_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content ;
    gap: 40px;
    position: relative;
    width: 100%;
    height: max-content;
    justify-items: center;
    align-items: top;
    overflow: hidden;
}

.create_grid span {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content ;
    gap: 20px;
    position: relative;
    width: 100%;
    height: max-content;
    justify-items: center;
    align-items: top;
    overflow: hidden;
}