.signUp {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    position: relative;
    width: 100%;
    height: max-content;
    justify-items: center;
    align-items: center;
}