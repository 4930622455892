.carousel_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    width: 100vw;
    margin: 20vh 0;
    padding: 0;
    justify-items: center;
    align-items: center;
}

.carousel {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    gap: 40px;
    width: 95%;
    min-width: 400px;
    max-width: 100vw;
    height: 450px;
    position: relative;
    margin: 0;
    padding: 0;
    /* background-color: var(--primary); */
    justify-items: center;
    align-items: center;
    overflow: hidden;
}

.carouselcard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    width: 300px;
    height: 300px;
    margin: 0;
    padding: 10px 20px;
    background-color: var(--secondary);
    border-radius: 10px;
    justify-items: center;
    align-items: center;
    transition: all 250ms linear;
    cursor: pointer;
    z-index: 1;
}

.carouselcard:hover {
    /* width: 310px; */
    height: 310px;
    z-index: 2;
}

.carouselcard img {
    position: absolute;
    top: 0px;
    width: 275px;
    height: auto;
}

.carouselcard h1 {
    width: 100%;
    margin: 0;
    padding: 0 50px;
    align-self: flex-end;
    font-size: 24px;
    text-align: right;
}

.icon_update {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    position: relative;
    top: 0vh;
    width: 100%;
    height: max-content;
    margin: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    backdrop-filter: blur(5px);
    justify-items: center;
    align-items: center;
    transition: all 250ms linear;
    z-index: 10;
}

.icon_update input {
    width: 90%;
    min-width: 200px;
    max-width: 300px;
}

.scroll_bar {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: var(--gridGap);
    position: absolute;
    bottom: -100px;
    width: max-content;
    max-width: 100vw;
    height: 50px;
    margin: 0px;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    z-index: 10;
}

.carouselcard_blip {
    width: 20px;
    height: 20px;
    background-color: var(--secondary);
    border-radius: 100%;
    cursor: pointer;
}

@media screen and (max-width: 900px) {

    .carousel {
        display: grid;
        grid-auto-columns: 100vw;
        gap: 5px;
        width: 95%;
    }

    .scroll_bar {
        position: fixed;
        top: 0px;
        width: 100%;
        height: 100px;
        background-color: var(--bg);
    }
    
  }
