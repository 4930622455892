.scheduler_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content 1fr;
    position: relative;
    width: 100%;
    height: max-content;
    margin: 20px;
    padding: 10px;
    border: solid 1px var(--light);
    border-radius: 10px;
    justify-items: center;
    align-items: top;
  }
  
  .scheduler {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
  }

  .schedulebar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--gridGap);
    width: 95%;
    height: max-content;
    padding: 5px;
    border: solid 1px var(--light);
    border-radius: 10px;
    justify-items: center;
  }

  .schedulercard {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: var(--gridGap);
    position: relative;
    width: 95%;
    height: max-content;
    padding: 0 0 20px 0;
    border-bottom: dotted 2px var(--light);
    justify-items: center;
    align-items: center;
  }


  .schedulercard .row {
    gap: 5px;
  }

  .schedulercard .row:last-child {
    display: grid;
    grid-template-rows: max-content 1fr;
    width: 50px;
    height: 95%;
    justify-items: center;
    align-items: center;
  }

  .schedulercard:last-child {
    border: none;
  }

  .schedulercard h1 {
    font-size: 16px;
    font-weight: 600;
  }

  .schedulercard h3 {
    width: 95%;
    height: 30px;
    padding: 0px 0px 0px 10px;
    background-color: var(--light);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 30px;
  }

  .schedulercard h4 {
    font-size: 12px;
  }

  .status_actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content;
    gap: var(--gridGap);
    position: absolute;
    top: center;
    right: 75px;
    height: max-content;
    padding: 10px;
    background-color: var(--bg);
    border: solid 1px var(--light);
    border-radius: 10px;
    box-shadow: 0 0 100px -1px rgba(0, 0, 0, .3);
    justify-items: center;
    align-items: center;
    z-index: 1;
  }

  .status_actions .col {
    display: grid;
    grid-template-columns: 1fr max-content;
    width: 100%;
    justify-items: left;
  }

  .status_actions .col h1 {
    font-size: 12px;
    font-weight: 300;
  }
  
  .status_actions .col input {
    width: 2vw;
    height: auto;
  }