.hero {
    display: grid;
    position: relative;
    width: 100%;
    height: 25vh;
    max-height: 150px;
    background-color: var(--secondary);
    /* background-image: url("../src/images/jpg/bull-in-field.jpg"); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

.hero_btn {
    display: grid;
    position: relative;
    margin: 0;
    padding: 5px;
    overflow: hidden;
}
