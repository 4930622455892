.video_context_menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    position: relative;
    width: 100%;
    max-width: 300px;
    height: max-content;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
}