@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap');

:root {
  /* --bg: rgb(216, 199, 122); */
  --bg: rgb(7, 7, 7);
  --secondary: rgba(255, 195, 0, 1);
  --tertiary: rgba(255, 255, 255, 1);
  --primary: rgba(90, 90, 95, 1);
  --darkAccent: rgba(30, 30, 30, 1);

  --wrapper_bg: var(--bg);
  --layoutGridGap: 30px;
  --gridGap: 10px;
  --link: rgb(244, 223, 154);
  --notice: rgb(255, 220, 0, 1);
  --labelColor: rgba(127, 127, 127, 1);
  --tint: rgba(223, 223, 223, .25);
  --shade: rgba(0, 0, 0, 0.035);

  --icon: rgba(255, 158, 27, 1);
  --dark: rgba(0, 0, 0, 0.6);
  --light: rgba(255, 255, 255, 0.03);
  --darkgrey: rgba(100, 100, 100, .3);
  --lightgrey: rgba(244, 244, 244, 1);
  --grey: rgba(200, 200, 200, 1);
  --darkText: var(--bg);
  --lightText: var(--tertiary);
  --text: var(--primary);
  --linkHover: rgba(190, 190, 190, 1);
  --globalTopMargin: 0px;
  --maxScreen: 900px;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Vollkorn SC", serif;
  font-size: 12pt;
  line-height: 1.25em;
  /* cursor: none; */
}

html {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*Defines Responsive Text*/
  overflow-x: hidden;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bg);
  color: var(--primary);
  text-align: center;
  justify-items: center;
  align-items: center;
  overflow-x: hidden;
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  justify-items: center;
  align-items: top;
  overflow-x: hidden;
}

.display_layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  width: 100%;
  min-width: 280px;
  max-width: 400px;
  padding: 50px 0 50px 0;
  justify-items: center;
  align-items: top;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  position: relative;
  width: 95%;
  /* min-width: 280px; */
  max-width: calc(900px - 50px); 
  height: max-content;
  margin: 10px 0;
  padding: 25px 0 0 0;
  /* background-color: var(--shade); */
  /* backdrop-filter: blur(5px); */
  border-radius: 10px;
  /* border: solid 2px var(--primary); */
  border: "none";
  outline-offset: 0px;  
  box-shadow: 0 0 0px -1px rgba(0, 0, 0, 0.6);
  justify-items: center;
  align-items: center;
}

.wrapper h1 {
  position: relative;
  top: -20px;
  padding: 5px 10px;
  background-color: var(--bg);
  border-radius: 100px;
  font-size: 16px;
  justify-self: left;
  z-index: 2;
}

.copy_wrapper {
  display: grid;
  justify-items: left;
}

.modal_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  position: fixed;
  right: 20px;
  /* width: calc(100vw - 400px); */
  width: 70vw;
  height: 100vh;
  max-height: max-content;
  margin: 0;
  padding: 0;
  background-color: var(--primary);
  border-radius: 0px;
  /* border: solid 2px var(--primary); */
  outline-offset: 0px;  
  /* box-shadow: 0 0 0px -1px rgba(0, 0, 0, 0.6); */
  justify-items: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  gap: var(--gridGap);
  position: fixed;
  top: 25vh;
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  height: max-content;
  margin: 30px;
  padding: 30px;
  /* background-color: rgba(244, 244, 244, .25); */
  border-radius: 20px;
  /* box-shadow: 0 0 530px -10px rgba(0, 0, 0, .71); */
  justify-items: center;
  align-items: center;
  backdrop-filter: blur(15px);
}

.component_wrapper_fixed {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  gap: var(--gridGap);
  position: fixed;
  width: max-content;
  height: max-content;
  justify-items: center;
  align-items: top;
  z-index: 20;
}

.component_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100%;
  max-width: 300px;
  height: max-content;
  min-height: 100vh;
  margin: 0;
  padding: 250px 0;
  background-color: var(--primary);
  backdrop-filter: blur(5px);
  justify-self: center;
  align-self: center;
  transition: all 1s ease;
  overflow: hidden;
  z-index: 1000;
}

.form_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;
  left: 0;
  top: 0vh;
  width: 100%;
  height: 100%;
  /* max-width: 700px; */
  /* max-height: 300px; */
  padding: 20px;
  background-color: var(--dark);
  border-radius: 0px;
  outline: none;
  outline-offset: -15px;  
  box-shadow: 0 0 0px -1px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  justify-items: center;
  align-items: center;
  transition: all 1s ease;
  z-index: 1000;
}

span {
  display: grid;
  justify-items: left;
}

.div_btn {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: max-content;
  width: 80%;
  height: max-content;
  padding: 15px 20px 15px 20px;
  border: solid 1px var(--light);
  border-radius: 20px 100px 100px 20px;
  box-shadow: 0 0 0px 0px var(--darkAccent);
  justify-items: left;
  align-items: center;
  transition: all 200ms linear;
  cursor: pointer;
}

.div_btn:hover {
  box-shadow: 0 0 40px -1px rgba(0, 0, 0, 0.3);
}

.avacon {
  width: 7vw;
  height: 7vw;
  object-fit: cover;
  border-radius: 10px;
}

/* .header {
  display: none;
  position: absolute;
  top: 0;
  left: 10px;
  width: max-content;
  height: 30px;
  padding: 5px 10px 5px 5px;
  background-color: var(--wrapper_bg);
  font-size: 24px;
  justify-items: center;
  align-items: center;
  z-index: 2;
} */

.hamburger {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  right: 20px;
  justify-items: center;
  justify-self: right;
  align-items: center;
  z-index: 1000;
}

.timestamp {
  margin: 0 5vw 0 0;
  padding: 3px 10px 3px 10px;
  background-color: var(--light);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 7pt;
  justify-self: right;
}

.notice {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: fixed;
  width: max-content;
  height: max-content;
  padding: 5px;
  background-color: var(--dark);
  border: solid 1px var(--light);
  border-radius: 5px;
  justify-items: center;
  align-items: center;
  text-align: center;
  z-index: 100;
}

.notice > p {
  font-size: 10pt;
  text-indent: 0;
  text-align: center;
}

label {
  font-size: 12pt;
  justify-self: left;
}

form {
  display: grid;
  grid-template-columns: max-content;
  grid-auto-rows: max-content;
  gap: var(--gridGap);
  width: max-content;
  justify-items: center;
  align-items: center;
}

input, textarea, select {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  min-width: 50px;
  max-width: 60vw;
  height: 30px;
  margin: 0;
  background-color: rgba(242, 238, 231, 1);
  border: none;
  border-radius: 5px;
  color: rgba(90, 90, 90, .5);
  font-size: 12pt;
  font-weight: 0;
  transition: all 1s ease;
  text-indent: 5px;
}

input[type="number"] {
  max-width: 50px;
}

textarea {
  height: 100px;
  resize: vertical;
}

input:focus, textarea:focus {
  border: none;
  outline: none;
  transition: all 1s ease;
}

button {
  display: grid;
  height: max-content;
  margin: 5px;
  padding: 5px 10px 5px 10px;
  background-color: var(--tint);
  border: none;
  border-radius: 10px;
  font-size: 12pt;
  font-weight: 300;
  transition: all 1s ease;
  color: var(--primary);
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

button:hover {
  background-color: rgba(255, 255, 255, 1);
  transition: all 1s ease;
}

button svg {
  fill: var(--secondary) !important;
}

svg {
  opacity: 1;
  transition: all 1s ease;
}

svg:hover {
  transition: all 1s ease;
}

p {
  width: 100%;
  font-size: 12pt;
  font-weight: 300;
  text-align: left;
  text-indent: 20px;
  word-wrap: break-word;
  word-break: break-word;
}

a {
  margin: 10px;
  font-size: 10pt;
  font-weight: 600;
  text-decoration: none !important;
}

.site_link_format {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

h1 {
  margin: 0px;
  font-size: 24px;
  font-weight: 300;
}

h2 {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
}

h5 {
  margin: 0px;
  font-size: 10px;
  font-weight: 200;
}

h6 {
  margin: 0px;
  font-size: 10px;
  font-weight: 700;
}

.col {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: var(--gridGap);
  width: 100%;
  justify-items: center;
  align-items: center;
}

.row {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  gap: var(--gridGap);
  width: 100%;
  justify-items: left;
  align-items: center;
}

hr {
  width: 90%;
  margin: 20px 0 20px 0;
  border: none;
  border-top: solid 5px var(--light);
}

.invisible {
  opacity: 0;
}

.vertical_rule {
  width: 1px;
  height: 100%;
  margin: 50px;
  border-left: dotted 1px var(--light);
  opacity: .1;
}

.circle_wrapper {
  display: grid;
  position: relative;
  width: 10vw;
  max-width: 75px;
  height: 10vw; 
  max-height: 75px;
  background-color: var(--tint);
  border: solid 1px var(--light);
  border-radius: 100%;
  justify-items: center;
  align-items: center;
  overflow: hidden;
}

.circle_wrapper svg {
  margin: 0;
  padding: 0;
}

.circle_wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.close_btn {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  position: absolute;
  width: max-content;
  height: max-content;
  background-color: var(--tint);
  border-radius: 100px;
  justify-items: center;
  align-items: center;
  justify-self: right;
  align-self: top;
}



@media screen and (max-width: 900px) {



}