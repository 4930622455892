.about_layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: 30px;
    position: relative;
    width: 100vw;
    height: max-content;
    /* min-height: 99vh; */
    padding: 0px;
    justify-items: center;
    align-items: center;
}

.about_layout span {
    max-width: 500px;
    justify-items: center;
    align-items: center;
}

.about_layout p {
    width: 85%;
    margin: 0 0 20px 0;
    column-count: 1;
    column-gap: 30px;
}

.about_layout img {
    border-radius: 20px;
    width: 60%;
    max-width: 500px;
    margin: 10vh 0 0 0;
}