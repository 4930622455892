.roster {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: max-content;
    gap: var(--gridGap);
    position: relative;
    width: 99%;
    /* max-width: 400px; */
    height: max-content;
    padding: 10px 0 10px 0;
    border: solid 1px var(--light);
    border-radius: 10px;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
}

.rostercard {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: repeat(3, max-content);
    gap: var(--gridGap);
    width: 90%;
    height: max-content;
    min-height: 60px;
    margin: 0px;
    padding: 10px;
    border: solid 1px var(--light);
    border-radius: 10px;
    box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0);
    text-overflow: ellipsis;
    justify-items: left;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.rostercard h1 {
    color: var(--primary);
    font-size: 14px;
    font-weight: 900;
}

.rostercard h2 {
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
}

.rostercard h3 {
    color: var(--primary);
    font-size: 10px;
    font-weight: 400;
}

.rostercard h4 {
    color: var(--primary);
    font-size: 10px;
    font-weight: 400;
}

.roster_profile_view {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    position: fixed;  
    top: 0;
    right: 0;      
    width: 100%;
    max-width: 600px;
    height: 100vh;
    margin: 0;
    padding: 100px 25px;
    background-color: rgba(0, 0, 0, 1);
    justify-items: center;
    align-items: flex-start;
    transition: all 1s ease;
    z-index: 1000;
}
