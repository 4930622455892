.create_course_form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 85%;
    max-width: 90vw;
    height: max-content;
    /* min-height: 300px; */
    padding: 20px;
    /* background-color: var(--secondary); */
    border: solid 1px var(--tint);
    border-radius: 20px;
    justify-items: center;
    align-items: flex-start;
    justify-self: center;
    align-self: center;
    transition: all 1s ease;
    overflow: auto;
    z-index: 1;
}

.create_course_form .row textarea {
    width: 100%;
    max-width: none;
    resize: none;
}

.create_course_form .col {
    grid-template-columns: 1fr 1fr;
    justify-items: left;
}

.component_wrapper h1,
.component_wrapper h2,
.component_wrapper h3,
.component_wrapper h4,
.component_wrapper h5,
.component_wrapper h6,
.component_wrapper p,
.component_wrapper label {
    color: var(--bg);
}

.create_course_form_wrapper h1 {
    margin: 10px;
}

@media screen and (max-width: 900px) {

    .create_course_form_wrapper {
        padding: 0;

    }
  
  }